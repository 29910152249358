import React from 'react'
import { QuizSection } from '../components/page/index/quiz/quizSection'
import Layout from '../components/layout/layout'
import socialImage from '../images/quiz/socialShare.jpg'

export default function QuizPage() {
  return (
    <Layout
      meta={{
        title: 'Karma Quiz: Myth or Fact?',
        description:
          'Take the Karma quiz and find out if you’re ready to unravel karma!',
        image: socialImage,
      }}
      showPenguinLogo={false}
    >
      <QuizSection variant={'page'} />
    </Layout>
  )
}
